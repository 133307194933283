import React from "react";
import PostEditor from "components/editor/postEditor";

function EditorContainer() {
  return (
    <>
      <PostEditor />
    </>
  );
}

export default EditorContainer;
